import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
   original: string;
 }
export const imagesWildlife = [
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556921/fav-110_q2pnw1.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556921/fav-110_q2pnw1.jpg",
       width: 320,
       caption: "Barn Owl",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723555747/JT3A0641-Enhanced-NR_e8mm9j.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723555747/JT3A0641-Enhanced-NR_e8mm9j.jpg",
       width: 320,
       alt: "Kestrel Hovering for Food",
       caption: "Kestrel Hovering for Food",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556918/fav-107_ron4qe.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556918/fav-107_ron4qe.jpg",
       width: 320,
       height: 212,
       caption: "Turtles in Curaçao",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556919/fav-108_xud6ah.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556919/fav-108_xud6ah.jpg",
       width: 320,
       height: 212,
       caption: "Harvest Mouse",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556915/fav-102_ogi4j8.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556915/fav-102_ogi4j8.jpg",
       width: 320,
       height: 212,
       caption: "Red Squirrel in Berlin",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-12_kqjqqo.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-12_kqjqqo.jpg",
       width: 320,
       height: 212,
       caption: "Another Red Squirrel",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556916/fav-104_uolc8w.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556916/fav-104_uolc8w.jpg",
       width: 160,
       height: 212,
       caption: "Bullfinch Reflection",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556920/fav-109_mgbjax.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556920/fav-109_mgbjax.jpg",
       width: 320,
       height: 212,
       caption: "Yorkshire Dales Ponies",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723555431/IMG_1276_jtrfxf.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723555431/IMG_1276_jtrfxf.jpg",
       width: 320,
       height: 212,
       caption: "Blue Butterfly",
    },
    ];