import React from 'react';

import { Link } from 'react-router-dom';
import { useState } from 'react';
import emailjs from '@emailjs/browser';// using https://dashboard.emailjs.com to send via mailgun
const AboutMe = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
            console.log('error',error);
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
    // Clears the form after sending the email
    e.target.reset();
  };
    return (
        <>
        <article className="message">
            <div className="message-header">
            <p>A little about me</p>
            </div>
            <div className="message-body">
               <p>
                I am a keen photographer, living in the Yorkshire Dales and love travelling to meet new people and cultures.
                My main photos are of landscapes, but I also love wildlife. I started with an old film camera but in recent years have kept 
                up to date with the latest Canon kit, which I love.
                I enjoy running and walking, though sometimes carrying my camera gear can be cumbersome. I'm also a bellringer,
                which is a great hobby, and play the guitar and sometimes a banjolele.
                </p>
                <p>
                I'm a semi-retired software developer, now only working Tuesday, Wednesday and Thursday, which apparently makes me a TWAT, but I love a four-day weekend. 
                I started writing software in the early 1990s, then when I discovered Borland Delphi in 1995,
                I think I found my ideal job. I even still use Delphi today as some of the systems I have developed have been running for over 20 years.
                I love writing for iOS, though not necessarily with Apple's languages as I like&nbsp;
                <Link to="https://www.remobjects.com/elements/">Elements from RemObjects</Link> for 
                many reasons. 
                </p>
                <p>
                I wrote an app for bellringers, to keep track of towers we visit and any peals and quarter peals rung, which is available&nbsp;
                <Link to="https://apps.apple.com/gb/app/tower-visit/id1252240821">on the app store</Link> for iPhone or iPad.
                It is not available for Android as I wrote this for my own use, and I like Apple products. I've made it free
                for all to use.
                </p>
                <p>
                More recently, I learnt how to use&nbsp;
                <Link to="https://react.dev">React</Link> for a new web application addition to an existing system so for fun I 
                decided to write my website with it and put a few of my photos on here, rather than using Wordpress that my earlier site was created with.
                </p>
            </div>
            <div className='message-header'>
                <p>My Camera Gear</p>
            </div>
            <div className="message-body">
                <div className='list'>
                    <div className="list-item">
                      <div className="list-item-content">
                        <div className='list-item-title'>Canon R6 Mark ii</div>  
                      </div>
                    </div>
                    <div className="list-item"> Canon 100-500mm f/4.5</div>
                    <div className="list-item"> Canon 24-105mm f/4.5</div>
                    <div className="list-item"> Canon 70-200mm f/4</div>
                    <div className="list-item"> Canon 16-35mm f/4</div>
                    <div className="list-item"> Canon 24mm f/1.4</div>
                    <div className="list-item"> Canon Speedlite 580ex ii</div>
                </div>
            </div>
            <div className='message-header'>
                <p>Contact me</p>
            </div>
            <div className="message-body">
                    <div className="columns">
                        <div className="column is-half">
                        <form onSubmit={sendEmail}>
                            <div className="field">
                            <label className="label">Name</label>
                            <div className="control has-icons-left ">
                                <span className="icon is-small is-left">
                                <i className="fas fa-user"></i>
                                </span>
                                <input className="input" name="from_name" type="text" placeholder="Your Name"/>
                            </div>
                            </div>
                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control has-icons-left">
                                    <span className="icon is-small is-left">
                                    <i className="fas fa-envelope"></i>
                                    </span>
                                    <input className="input" name="from_email" type="email" placeholder="Your Email"/>
                                </div>
                            </div>
                            <div className="field">
                            <label className="label">Message</label>
                            <div className="control">
                                <textarea className="textarea" name="message" disabled={isSubmitting} placeholder="Your Message"></textarea>
                            </div>
                            </div>
                            <div className="field">
                            {stateMessage && <p>{stateMessage}</p>}
                            <div className="control">
                                <button className="button is-primary">Send message</button>
                            </div>                              
                            </div>
                        </form>
                        </div>
                    </div>
            </div>
        </article>
        </>
    );
};

export default AboutMe;